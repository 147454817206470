import { render, staticRenderFns } from "./AddNewDiscount.vue?vue&type=template&id=4b929b7d&scoped=true"
import script from "./AddNewDiscount.vue?vue&type=script&lang=js"
export * from "./AddNewDiscount.vue?vue&type=script&lang=js"
import style0 from "./AddNewDiscount.vue?vue&type=style&index=0&id=4b929b7d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b929b7d",
  null
  
)

export default component.exports